.swiper {
  width: 100%;
}

.swiper-slide {
  display: flex;
}

@media only screen and (max-width: 700px) {
  .swiper {
    height: 25rem;
  }
}

@media only screen and (min-width: 700px) {
  .swiper {
    height: 38rem;
  }
}

@media only screen and (min-width: 1536px) {
  .swiper {
    height: 50rem;
  }
}

@media only screen and (min-width: 2100px) {
  .swiper {
    height: 60rem;
  }
}

@media only screen and (min-width: 3000px) {
  .swiper {
    height: 70rem;
  }
}
